import React from 'react';
import {Button, notification} from 'antd';
import {getNewOutlet, getOutlet} from 'reconnect.js';

getNewOutlet(
  'setup-wizard',
  {
    visible: false,
    steps: [],
    initialized: false,
    curStepIdx: 0,
    checkAchieve: async (stepKey) => {
      console.log('here!', stepKey);
      let wizardOutlet = getOutlet('setup-wizard');
      let steps = wizardOutlet.getValue().steps;
      let foundStepIdx = steps.findIndex((s) => s.key === stepKey);
      let foundStep = foundStepIdx !== -1 ? steps[foundStepIdx] : null;
      let nextStepIdx =
        foundStepIdx < steps.length - 1 ? foundStepIdx + 1 : null;
      if (foundStep) {
        let result = await foundStep.checkAchieve();
        if (result) {
          let _notificationKey = `wizard-${stepKey}`;
          notification.success({
            key: _notificationKey,
            message: `您已完成 "${foundStep.title}"`,
            description: (
              <>
                此步驟已完成 <br />
                <br />
                {nextStepIdx !== null && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setCurStepIdx(nextStepIdx);
                      notification.close(_notificationKey);
                    }}>
                    下一步
                  </Button>
                )}
              </>
            ),
            duration: null,
          });
          setStepAchieve(stepKey, true);
          showWizard(true);
        } else {
          // TODO: show notification for detail info, how to do it.
          notification.warn({
            message: '此步驟尚未完成！',
            description: '',
            duration: null,
          });
        }
      }
      return false;
    },
  },
  {autoDelete: false},
);

export function setCurStepIdx(stepIdx) {
  try {
    let wizardOutlet = getOutlet('setup-wizard');
    let wizard = wizardOutlet.getValue();
    wizardOutlet.update({
      ...wizard,
      curStepIdx: stepIdx,
    });
  } catch (err) {
    console.log('error?', err);
  }
}

export function setStepAchieve(stepKey, achieve) {
  try {
    let wizardOutlet = getOutlet('setup-wizard');
    let wizard = wizardOutlet.getValue();
    let _updateSteps = [...wizard.steps];
    let _foundIdx = _updateSteps.findIndex((s) => s.key === stepKey);
    if (_foundIdx !== -1) {
      _updateSteps[_foundIdx] = {
        ..._updateSteps[_foundIdx],
        achieve,
      };
    }
    wizardOutlet.update({
      ...wizard,
      steps: _updateSteps,
    });
  } catch (err) {
    console.log('error?', err);
  }
}

export function showWizard(show) {
  let wizardOutlet = getOutlet('setup-wizard');
  wizardOutlet.update({
    ...wizardOutlet.getValue(),
    visible: show,
  });
}

export function setWizardSteps(steps) {
  let wizardOutlet = getOutlet('setup-wizard');
  wizardOutlet.update({
    ...wizardOutlet.getValue(),
    steps,
    initialized: true,
  });
}
