import React from 'react';
import styled from 'styled-components';
import {Button, Steps, message} from 'antd';
import {getOutlet, useOutlet} from 'reconnect.js';
import {Close} from '@styled-icons/evil/Close';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {SITE_CONFIG, WIZARD_STEP_KEY, TIERS} from '../../constants';
import {setCurStepIdx, setWizardSteps, showWizard} from './SetupWizardOutlet';
const Step = Steps.Step;

function SetupWizard(props) {
  const [wizard, setWizard] = useOutlet('setup-wizard');
  const [selectedExpand, setSelectedExpand] = React.useState(0);
  const [user] = useOutlet('user');
  const [data, setData] = React.useState(null);
  const {visible} = wizard;

  // console.log('wizard', wizard);
  React.useEffect(() => {
    if (!data) {
      return;
    }
    const _setInitialSteps = async () => {
      let initialSteps = [
        {
          key: WIZARD_STEP_KEY.INITIAL_STORE_CONFIG,
          title: '設定店家資訊',
          summary: '',
          description: (
            <>
              <p>設定您的店家名稱、描述、收款帳號</p>
              <Button
                type="primary"
                style={{margin: '10px 0'}}
                onClick={() =>
                  AppActions.navigate(
                    `/admin/config/?action=detail&id=${
                      data?.[SITE_CONFIG.config.value].id
                    }`,
                  )
                }>
                前往
              </Button>
            </>
          ),
          checkAchieve: async () => {
            // TODO: return data for successDescription
            try {
              const data = await JStorage.fetchOneDocument('site', {
                name: SITE_CONFIG.config.value,
                store_id: user.store_id,
              });
              if (
                data.store_title &&
                data.store_description &&
                data.store_bank_account &&
                data.store_bank_name &&
                data.store_bank_branch
              ) {
                return true;
              }
            } catch (err) {
              //
              console.log('err??', err);
            }
            return false;
          },
          successDescription: `已設定完成`,
          achieve: null,
        },
        {
          key: WIZARD_STEP_KEY.SETUP_PRODUCTS_CATEGORY,
          title: '設定商品分類',
          summary: '',
          description: (
            <>
              <p>分類您的商品，讓顧客更快速地找到他們要的商品</p>
              <Button
                type="primary"
                style={{margin: '10px 0'}}
                onClick={() =>
                  AppActions.navigate(
                    `/admin/product_category/?action=detail&id=${
                      data?.[SITE_CONFIG.product_category.value].id
                    }`,
                  )
                }>
                前往
              </Button>
            </>
          ),
          achieveMsg: '',
          achieveFailMsg: '',
          checkAchieve: async () => {
            try {
              const data = await JStorage.fetchOneDocument('site', {
                name: SITE_CONFIG.product_category.value,
                store_id: user.store_id,
              });
              if (data?.categories?.length > 0) {
                return true;
              }
            } catch (err) {
              //
            }
            return false;
          },
          achieve: null,
        },
        {
          key: WIZARD_STEP_KEY.CREATE_FIRST_PRODUCT,
          title: '創建您的第一個商品',
          summary: '',
          description: (
            <>
              <p>立即上架第一個商品</p>
              <Button
                type="primary"
                style={{margin: '10px 0'}}
                onClick={() =>
                  AppActions.navigate('/admin/products/?action=create')
                }>
                前往
              </Button>
            </>
          ),
          checkAchieve: async () => {
            try {
              const data = await JStorage.fetchDocuments(
                'product',
                {
                  store_id: user.store_id,
                },
                [],
                {offset: 0, limit: 1},
              );
              if (data.total >= 1) {
                return true;
              }
            } catch (err) {
              //
              console.log('err??', err);
            }
            return false;
          },
          achieve: null,
        },
        {
          key: WIZARD_STEP_KEY.PREVIEW_STORE,
          title: '預覽您的商店',
          summary: '',
          description: (
            <>
              <p>透過預覽查看您的商店如何呈現在顧客面前</p>
              <Button
                type="primary"
                style={{margin: '10px 0'}}
                onClick={() => AppActions.navigate('/admin/preview/')}>
                前往
              </Button>
            </>
          ),
          checkAchieve: async () => {
            let isAchieve =
              window.localStorage.getItem(
                `${user.store_id}_wizard_${WIZARD_STEP_KEY.PREVIEW_STORE}`,
              ) === 'true';
            return isAchieve;
          },
          achieve: null,
        },
        {
          key: WIZARD_STEP_KEY.UPGRADE_PLAN,
          title: '升級您的方案',
          summary: '',
          description: (
            <>
              <p>升級方案以擁有完整服務</p>
              <Button
                type="primary"
                style={{margin: '10px 0'}}
                onClick={() => AppActions.navigate('/admin/')}>
                前往
              </Button>
            </>
          ),
          checkAchieve: async () => {
            try {
              const userOutlet = getOutlet('user');
              const user = userOutlet.getValue();
              if (user.tier && user.tier !== TIERS[0].name) {
                return true;
              }
            } catch (err) {
              //
            }
            return false;
          },
          achieve: null,
        },
        {
          key: WIZARD_STEP_KEY.CHECK_BILLING,
          title: '確認您的帳單',
          summary: '',
          description: (
            <>
              <p>查看繳費記錄</p>
              <Button
                type="primary"
                style={{margin: '10px 0'}}
                onClick={() => AppActions.navigate('/admin/billing/')}>
                前往
              </Button>
            </>
          ),
          checkAchieve: async () => {
            let isAchieve =
              window.localStorage.getItem(
                `${user.store_id}_wizard_${WIZARD_STEP_KEY.CHECK_BILLING}`,
              ) === 'true';
            return isAchieve;
          },
          achieve: null,
        },
        // {
        //   title: "",
        //   summary: "",
        //   description: "",
        //   checkAchieve: async () => {
        //     return false
        //   },
        //   achieve: null,
        // },
      ];
      try {
        let _steps = [];
        let _currStepIdx = null;
        for (let [idx, step] of initialSteps.entries()) {
          let achieve = await step.checkAchieve();
          if (!achieve && _currStepIdx === null) {
            _currStepIdx = idx;
          }
          _steps.push({
            ...step,
            achieve,
          });
        }
        setCurStepIdx(_currStepIdx);
        setWizardSteps(_steps);
      } catch (err) {
        //
      }
    };

    if (!wizard.initialized) {
      _setInitialSteps();
    }
  }, [data, wizard.initialized]);

  const expandProps = {
    selectedExpand,
    setSelectedExpand,
  };

  React.useEffect(() => {
    const _fetchDefault = async () => {
      try {
        console.log('start fetch in setup wizard!!');
        let data = await JStorage.fetchDocuments(
          'site',
          {store_id: user.store_id},
          [],
          null,
        );
        setData(
          data.reduce((acc, curr, idx) => {
            acc[curr.name] = curr;
            return acc;
          }, {}),
        );

        let billing = await JStorage.fetchDocuments(
          'billing',
          {store_id: user.store_id},
          [],
          null,
        );

        if (billing.length === 0) {
          showWizard(true);
        }
      } catch (err) {
        //
      }
    };
    _fetchDefault();
  }, []);

  const uiSteps = React.useMemo(() => {
    const STEP_STATUS = {
      wait: 'wait',
      process: 'process',
      finish: 'finish',
      error: 'error',
    };

    let _uiSteps = wizard.steps.map((s, idx) => {
      let _status = STEP_STATUS.wait;

      if (wizard.curStepIdx === idx) {
        _status = STEP_STATUS.process;
      } else if (s.achieve) {
        _status = STEP_STATUS.finish;
      } else {
        _status = STEP_STATUS.wait;
      }
      return {
        ...s,
        status: _status,
      };
    });
    return _uiSteps;
  }, [wizard.steps, wizard.curStepIdx]);

  if (!data) {
    return <Wrapper visible={visible}>資料準備中...</Wrapper>;
  }

  return (
    <Wrapper visible={visible}>
      <h2 style={{marginBottom: 20}}>設定小幫手</h2>

      <Steps
        direction="vertical"
        current={wizard.curStepIdx}
        onChange={(current) => setCurStepIdx(current)}
        style={{overflow: 'auto'}}>
        {uiSteps.map((s, idx) => {
          return (
            <Step
              key={idx}
              status={s.status}
              title={s.title}
              subTitle={s.summary}
              description={
                wizard.curStepIdx === idx ? s.description : s.summary
              }
            />
          );
        })}
      </Steps>

      <div className="close-btn" onClick={() => showWizard(false)}>
        <Close />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  --wizard-width: 360px;
  position: fixed;
  z-index: 2;
  right: 0;
  top: 30px;
  bottom: 30px;
  width: var(--wizard-width);
  background-color: white;
  color: var(--text-color);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.4);
  padding: 60px 30px 20px;
  transition: all 200ms;
  transform: ${(props) =>
    props.visible ? 'translateX(0)' : 'translateX(var(--wizard-width))'};
  display: flex;
  flex-direction: column;

  & .content {
    width: 100%;
    height: 100%;
    overflow: auto;

    & .setup-item {
      & p {
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }
  }

  & .close-btn {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
`;

export default SetupWizard;
export {showWizard};
