import React from 'react';
import styled from 'styled-components';
import {Button, Layout, Result, Space, message, Menu} from 'antd';
import * as AppActions from '../../AppActions';
import {getOutlet, useOutlet, useOutletSetter} from 'reconnect.js';
import * as User from 'rev.sdk.js/Actions/User';
import {withLoginRequired} from 'rev.sdk.js/Components/LoginRequired';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import SiteNavBar from '../SiteNavBar';
import AdminSearchOrderFixedButton from './AdminSearchOrderFixedButton';
import SetupWizard, {showWizard} from '../SetupWizard';
import {SITE_CONFIG} from '../../constants';

const Routes = [
  {name: '首頁', path: '/admin/'},
  {name: '設定', path: '/admin/site/'},
  {
    name: '商品',
    path: '__grounp-product',
    subroutes: [
      {name: '商品全覽', path: '/admin/products/'},
      {name: '庫存管理', path: '/admin/stocks/'},
    ],
  },
  {
    name: '訂單',
    path: '__grounp-order',
    subroutes: [
      {name: '訂單全覽', path: '/admin/orders/'},
      {name: '待處理', path: '/admin/orders/waiting/'},
      {name: '處理中', path: '/admin/orders/processing/'},
      {name: '已完成', path: '/admin/orders/done/'},
      {name: '已取消', path: '/admin/orders/canceled/'},
      {name: '匯出', path: '/admin/orders/export/'},
    ],
  },
  {name: '預覽', path: '/admin/preview/'},
  {name: '繳費記錄', path: '/admin/billing/'},
  {name: '重設密碼', path: 'reset-password'},
  {name: '小幫手', path: 'setup-wizard'},
  {name: '登出', path: 'logout'},
];

function getSelectedMenuKey() {
  if (typeof window === 'undefined') {
    return '';
  }

  const pathname = window.location.pathname;
  if (pathname[pathname.length - 1] !== '/') {
    return pathname + '/';
  }
  return pathname;
}

function AdminLayout(props) {
  const {children, location} = props;
  const [dimension] = useOutlet('dimension');
  const showResetPasswordModal = useOutletSetter('reset-password-modal');
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const mobile = !dimension.rwd || dimension.rwd === 'mobile';
  const [user] = useOutlet('user');

  const shouldShowOrderSearchButton = React.useMemo(() => {
    if (location.pathname.indexOf('/admin/orders') === 0) {
      return true;
    }
    return false;
  }, [location]);

  const getMenuItemProps = (path) => {
    if (path === 'reset-password') {
      return {
        onClick: () => showResetPasswordModal({admin: true}),
      };
    } else if (path === 'logout') {
      return {
        onClick: async () => {
          await User.logout(true);
          AppActions.navigate('/admin');
        },
      };
    } else if (path === 'setup-wizard') {
      return {
        onClick: () => {
          showWizard(true);
        },
      };
    }
    return {
      selected: path === location.pathname || path === location.pathname + '/',
      onClick: () => AppActions.navigate(path),
    };
  };

  React.useEffect(() => {
    setShowMobileMenu(false);
  }, [location]);

  React.useEffect(() => {
    const _initializeSiteConfig = async () => {
      AppActions.setLoading(true);
      try {
        let results = await JStorage.fetchDocuments(
          'site',
          {store_id: user.store_id},
          null,
          null,
        );
        for (let key in SITE_CONFIG) {
          let name = SITE_CONFIG[key].value;
          if (!results.find((r) => r.name === name)) {
            await JStorage.createDocument('site', {
              name,
              store_id: user.store_id,
            });
          }
        }
      } catch (ex) {
        message.warn('設定初始化失敗');
      }
      AppActions.setLoading(false);
      setInitialized(true);
    };

    if (!initialized && user?.store_id) {
      _initializeSiteConfig();
    }
  }, [initialized, user?.store_id]);

  const siderStyle = {
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    transition: 200,
    left: 0,
  };

  if (mobile) {
    siderStyle.left = showMobileMenu ? 0 : -200;
  }

  if (!user.store_id) {
    return null;
  }

  return (
    <Wrapper id="rev-admin-layout">
      <Layout>
        <Layout
          style={{marginLeft: mobile ? 0 : 200, backgroundColor: 'white'}}>
          {initialized && children}
        </Layout>

        <Layout.Sider theme="light" style={siderStyle}>
          <AppHeader
            style={{marginBottom: 40, cursor: 'pointer'}}
            onClick={() => AppActions.navigate('/')}
          />

          <Menu mode="inline" selectedKeys={[getSelectedMenuKey()]}>
            {Routes.map(({name, path, subroutes = []}, idx) => {
              if (subroutes.length === 0) {
                return (
                  <Menu.Item key={path} {...getMenuItemProps(path)}>
                    {name}
                  </Menu.Item>
                );
              }
              return (
                <Menu.SubMenu key={path} title={name}>
                  {subroutes.map(({name, path}) => (
                    <Menu.Item key={path} {...getMenuItemProps(path)}>
                      {name}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            })}
          </Menu>
        </Layout.Sider>

        {mobile && (
          <MobileMainMenu
            onClick={() => {
              setShowMobileMenu(!showMobileMenu);
            }}>
            選單
          </MobileMainMenu>
        )}

        {initialized && <SetupWizard />}

        {shouldShowOrderSearchButton && (
          <AdminSearchOrderFixedButton offsetBottom={mobile ? 110 : 32} />
        )}
      </Layout>
    </Wrapper>
  );
}

function AppHeader(props) {
  const {style = {}, onClick} = props;
  const [user] = useOutlet('user');

  return (
    <AppHeaderWrapper style={style} onClick={onClick}>
      <figure>
        <img src={'/images/bakey.png'} alt="site icon" />
      </figure>

      <div className="content">
        <h3>店家後台</h3>
        <p>{user.store_id}</p>
      </div>
    </AppHeaderWrapper>
  );
}

const Wrapper = styled.div`
  &#rev-admin-layout button.array-item-add {
    & span:first-child {
      transform: scale(1.2) translateY(1px);
    }

    & span:last-child {
      display: none;
    }
  }
`;

const AppHeaderWrapper = styled.header`
  background-color: white;
  display: flex;
  align-items: center;

  & > figure {
    padding: 10px;
    margin: 0px;

    & > img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }

  & > .content {
    padding: 8px;

    & h3 {
      color: rgba(0, 0, 0, 0.8);
      font-weight: 400;
    }

    & p {
      color: rgba(0, 0, 0, 0.8);
      padding: 0;
      margin: 0;
    }
  }
`;

function MenuItem(props) {
  const {selected, onClick} = props;
  return (
    <MenuItemWrapper selected={selected} onClick={onClick}>
      {props.children}
    </MenuItemWrapper>
  );
}

const MenuItemWrapper = styled.button`
  margin: 10px;
  width: 180px;
  border: none;
  background-color: ${(props) =>
    props.selected ? 'rgba(225,129,53,0.10)' : 'transparent'};
  color: ${(props) => (props.selected ? '#E18135' : '#ccc')};
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;

  &:hover {
    border: 1px solid #ccc;
  }
`;

const MobileMainMenu = styled.button`
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 1;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  border: none;
  outline: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withLoginRequired(AdminLayout, {
  admin: true,
  SiteNavBar,
  renderLoginRequired: (options) => {
    const loginModal = getOutlet('login-modal');
    return (
      <div
        style={{
          minHeight: '100vh',
          paddingTop: 'var(--topNavBarHeight)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Result
          icon={
            <img
              style={{width: 150}}
              src="/images/bakey.png"
              alt="permission-denied"
            />
          }
          title="尚無權限"
          subTitle="此頁面需要登入，方能瀏覽"
          extra={
            <Space direction="vertical">
              <Button
                onClick={() => {
                  loginModal.update({admin: true});
                }}>
                登入
              </Button>
            </Space>
          }
        />
      </div>
    );
  },
});
