import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';

const _BASIC_HEIGHT = 64;

function StoreHeader(props) {
  const [store] = useOutlet('store');

  return (
    <Wrapper>
      <div className="inner">
        <img src="/images/bakey.png" alt="site-icon" className="site-icon" />
        {store && (
          <h2 className="highlight">
            {store.config?.store_title || store.store_id}
          </h2>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #d2b48c;
  color: #fff;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 1;

  & img.site-icon {
    width: 42px;
    height: 42px;
    padding: 6px;
    border-radius: 50%;
    background-color: white;
    display: inline-block;
    object-fit: contain;
    margin-right: 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }

  & h2.highlight {
    font-weight: 100;
    font-size: 1.6rem;
    color: white;
    flex: 1;
  }

  & > .inner {
    height: ${_BASIC_HEIGHT}px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
`;

export default StoreHeader;
