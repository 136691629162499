export const THEME_COLOR = '#d2b48c';
export const SITE_CONFIG = {
  config: {display: '店家基本資料', value: 'config'},
  landing: {display: '首頁版面設定', value: 'landing'},
  product_category: {display: '商品類別設定', value: 'product_category'},
  line_config: {display: 'LINE整合設定', value: 'line_config'},
};

export const WIZARD_STEP_KEY = {
  INITIAL_STORE_CONFIG: 'initial-store-config',
  SETUP_PRODUCTS_CATEGORY: 'setup-products-category',
  CREATE_FIRST_PRODUCT: 'create-first-product',
  PREVIEW_STORE: 'preview-store',
  UPGRADE_PLAN: 'upgrade-plan',
  CHECK_BILLING: 'check-billing',
};

export const TIERS = [
  {
    name: 'T1',
    display: '試用版',
    description: '可建立商店但無法創建訂單',
    price: 0,
    features: [
      ['+', '免費建立商店'],
      ['+', '商品上架以及各種規格'],
      ['+', '客製化期間庫存管理'],
      ['-', '會員加LINE立即購'],
      ['-', '訂單狀態通知不漏接'],
      ['-', '後台管理訂單一站搞定'],
    ],
  },
  {
    name: 'T2',
    display: '正式版',
    description: '享有所有完整功能',
    price: 3999,
    features: [
      ['+', '免費建立商店'],
      ['+', '商品上架以及各種規格'],
      ['+', '客製化期間庫存管理'],
      ['+', '會員加LINE立即購'],
      ['+', '訂單狀態通知不漏接'],
      ['+', '後台管理訂單一站搞定'],
    ],
  },
];

export const BILLING_STATUS = {
  pending: 'pending',
  submitted: 'submitted',
  success: 'success',
  rejected: 'rejected',
};

export const BILLING_STATUS_DISPLAY = {
  pending: '尚未付款',
  submitted: '付款待確認',
  success: '付款成功',
  rejected: '款項有誤',
};
