import {getOutlet} from 'reconnect.js';
import {navigate as nav} from 'gatsby';
import * as User from 'rev.sdk.js/Actions/User';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as PathUtil from 'rev.sdk.js/Utils/PathUtil';
import Config from '../../data.json';
import * as jwt from '../Utils/jwt';
import NavUrl from 'rev.sdk.js/Utils/NavUrl';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as Serialize from '../Utils/Serialize';
import * as StockUtil from '../Utils/StockUtil';
import {WIZARD_STEP_KEY} from '../constants';
import qs from 'query-string';

const req = ApiUtil.req;
const LoadingOutlet = getOutlet('loading');
const ApiHookOutlet = getOutlet('ApiUtil');
const UserOutlet = getOutlet('user');

ApiHookOutlet.update({
  ...ApiHookOutlet.getValue(),
  onJson: (url, payload, jsonResp) => {
    // a sample hook, you can do whatever you want here
    return jsonResp;
  },
  onError: async (url, payload, resp) => {
    if (url.indexOf('token=') > -1 && resp.status === 410) {
      console.log('onError try autoLogin');
      const user = UserOutlet.getValue();
      const isAdmin = user.grp.split(':').indexOf('admin') !== -1;
      const result = await User.autoLogin({admin: isAdmin});
      if (result) {
        console.log('onError autoLogin success, fetch resource again', result);
        return req(url, payload, {ignoreOnErrorHook: true});
      }
      console.log('onError autoLogin failure, throw original error', result);
      throw resp;
    }
  },
  modifyFetchArgs: (url, fetchArgs) => {
    if (url.indexOf('/user/register') > -1) {
      url = url.replace('/user/register', '/store/register');
    } else if (url.indexOf('/user/forgot-password') > -1) {
      url = url.replace('/user/forgot-password', '/store/forgot-password');
    } else if (
      // all /cart routes exclude /cart/cvs-map should change to /store/cart/
      url.indexOf('/cart') > -1 &&
      url.indexOf('/cart/cvs-map') === -1
    ) {
      // update path
      url = url.replace('/cart', '/store/cart');

      // update query params
      const storeId = getOutlet('store').getValue().store_id;
      if (url.indexOf('calc') === -1) {
        url = url + `&store_id=${storeId}`;
      } else {
        url = url + `?store_id=${storeId}`;
      }
    } else if (
      // while accessing order collection via jstorage
      url.indexOf('/document/order/find?') > -1
    ) {
      // Both EC & Dashboard might use JStorage to fetch orders,
      // and we'd only want to intercept the EC part
      const isAdmin = getOutlet('user').getValue()?.grp?.indexOf('admin') > -1;
      if (!isAdmin) {
        try {
          // update http body to add query for store_id
          const data = JSON.parse(fetchArgs.body);
          const storeId = getOutlet('store').getValue().store_id;
          data.query = {
            ...data.query,
            store_id: storeId,
          };
          fetchArgs.body = JSON.stringify(data);
        } catch (ex) {
          console.warn('modifyFetchArgs:', ex);
        }
      }
    }

    return [url, fetchArgs];
  },
  modifyRedirectUrl: (url) => {
    const storeId = getOutlet('store').getValue().store_id;
    if (url.indexOf('/checkout/request') > -1) {
      const [before, after] = url.split('/checkout/request');
      url = before + '/store/checkout' + after + '&store_id=' + storeId;
    }
    return url;
  },
});

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function setLoading(loading, params = {}) {
  const {message = ''} = params;
  setTimeout(() => {
    LoadingOutlet.update({loading: loading, message: message});
  }, 0);
}

async function navigate(nextRoute, options = {loading: false}) {
  if (nextRoute instanceof NavUrl) {
    nextRoute = nextRoute.toString();
  }

  const StoreOutlet = getOutlet('store');
  const currRoute = PathUtil.normalizedRoute();
  nextRoute = PathUtil.normalizedRoute(nextRoute);

  if (currRoute !== nextRoute) {
    if (options?.loading) {
      LoadingOutlet.update({loading: true});
      if (typeof options.loading === 'number') {
        setTimeout(() => {
          LoadingOutlet.update({loading: false});
        }, options.loading);
      }
    }

    // append store id
    let store = StoreOutlet.getValue();
    let storeId = store?.store_id || null;
    if (storeId) {
      const {url, query} = qs.parseUrl(nextRoute);
      nextRoute = qs.stringifyUrl({url, query: {store: storeId, ...query}});
    }

    if (nextRoute.indexOf('/checkout') === 0) {
      nextRoute = '/store' + nextRoute;
    }

    if (nextRoute.indexOf('/profile') === 0) {
      nextRoute = '/store' + nextRoute;
    }

    nav(nextRoute);
  } else {
    console.log('path not changed, ignore...');
  }
  return null;
}

function adminCanAccess(user, options = {}) {
  return true;
}

/**
 * **************************************************
 * (client) JStorage powered product fetching APIs
 * **************************************************
 */
async function clientJStorageFetch(collection, {cat, sort, search, q}) {
  const StoreOutlet = getOutlet('store');
  let store = StoreOutlet.getValue();

  //"q" can defined custom query by project
  const catQuery = cat ? {labels: {$regex: cat}} : {};
  const searchQuery = search ? {searchText: {$regex: search}} : {};
  const sortValue = sort ? [sort] : ['-created'];
  const extraQueries = {};
  let projection = null;

  if (collection === 'product') {
    extraQueries.public = true;
    extraQueries.store_id = store?.store_id || null;
    extraQueries['$or'] = [
      {stock_type: {$exists: false}},
      {stock_type: 'always'},
      {stock_type: 'total'},
      {
        stock_type: 'period',
        stock_start_date: {
          $lte: new Date().toISOString().split('T')[0],
        },
        stock_end_date: {
          $gte: new Date().toISOString().split('T')[0],
        },
      },
    ];
  } else if (collection === 'Article_Default') {
    delete catQuery.labels;
    if (!cat) {
      catQuery.label = 'blog';
    } else {
      catQuery.label = {$regex: cat};
    }
    projection = {content: 0};
  }

  const resp = await JStorage.fetchDocuments(
    collection,
    {
      ...catQuery,
      ...searchQuery,
      ...extraQueries,
    },
    sortValue,
    null, // no paging for now, since our EC products shouldn't be too much
    projection, // if we're fetching Article, ignore the content
    {anonymous: true},
  );

  return resp;
}

const getDefaultCheckoutFormSpec = () => {
  const paymentSubTypes = [Cart.PAYMENT_SUBTYPE.offline];
  if (getOutlet('store').getValue().config.line_pay_enabled) {
    paymentSubTypes.push(Cart.PAYMENT_SUBTYPE.linepay);
  }

  return {
    paymentSubTypes,
    logisticsTypes: [Cart.LOGISTICS_TYPE.home, Cart.LOGISTICS_TYPE.self_pick],
    logisticsSubTypes: {
      [Cart.LOGISTICS_TYPE.cvs]: [
        Cart.LOGISTICS_SUBTYPE.famic2c,
        Cart.LOGISTICS_SUBTYPE.hilifec2c,
        Cart.LOGISTICS_SUBTYPE.unimartc2c,
      ],
      [Cart.LOGISTICS_TYPE.home]: [],
    },
    invoiceCategories: [Cart.INVOICE_CATEGORY.b2c, Cart.INVOICE_CATEGORY.b2b],
    invoiceCarrierTypes: [
      Cart.INVOICE_CARRIER_TYPE.none,
      Cart.INVOICE_CARRIER_TYPE.ecpay,
      Cart.INVOICE_CARRIER_TYPE.cdc,
      Cart.INVOICE_CARRIER_TYPE.mobile,
    ],
  };
};

function onCartLoaded(cart) {
  const checkoutFormSpec = getDefaultCheckoutFormSpec();

  const defaultUser = {
    buyer_name: cart.buyer_name || UserOutlet.getValue().data.name || '',
    buyer_email: cart.buyer_email || UserOutlet.getValue().data.email || '',
    buyer_phone: cart.buyer_phone || UserOutlet.getValue().data.phone || '',
    buyer_zip: cart.buyer_zip || UserOutlet.getValue().data.zip || '',
    buyer_city: cart.buyer_city || UserOutlet.getValue().data.city || '',
    buyer_district:
      cart.buyer_district || UserOutlet.getValue().data.district || '',
    buyer_address:
      cart.buyer_address || UserOutlet.getValue().data.address || '',
  };

  const updateConfig = {
    ...cart,
    ...defaultUser,
  };

  return {
    updateConfig,
    checkoutFormSpec,
  };
}

// 建立物流訂單 ( 通常會自行建立，此 api 用於意外發生，手動重新建立物流訂單 ）
async function createLogisticsOrder(id) {
  return await req(
    `${Config.apiHost}/order/logistics/create?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'post',
      data: {
        id: id,
      },
    },
  );
}

async function rebuild() {
  await req('https://api.netlify.com/build_hooks/615418bee44904a94bd7b4ab', {
    method: 'POST',
    data: {},
  });
}

async function fetchCustomResources(
  resource,
  {sort, keyword, filter, paging, extraQueries},
) {
  return null;
}

async function onLoginResult(err, result) {
  console.log('onLoginResult', err, result);
  if (!err) {
    try {
      setLoading(true);
      const isAdmin = result.grp.split(':').indexOf('admin') !== -1;
      if (!isAdmin) {
        const queryKey = Config.jstoreVersion !== 'v1' ? 'owner' : 'id';
        const profile = await JStorage.fetchOneDocument('user_profile', {
          [queryKey]: UserOutlet.getValue().username,
        });
        const privateProfile = await User.getPrivateProfile();

        UserOutlet.update({
          ...UserOutlet.getValue(),
          data: {
            ...profile,
            email: privateProfile.email,
            points: privateProfile.points,
          },
        });
        const decoded = await jwt.decodeToken(UserOutlet.getValue().token);
        console.log('VERIFY TOKEN SUCCESS', decoded);
        await Cart.fetchCart();
      } else {
        const decoded = await jwt.decodeToken(UserOutlet.getValue().token);
        const [_, store_id] = decoded.grp.split(':');
        console.log('VERIFY TOKEN SUCCESS', decoded);

        const storePrivateInfo = await fetchStorePrivateConfig();
        const store_configs = await fetchStoreConfig({
          store_id,
          useCache: false,
        });

        UserOutlet.update({
          ...UserOutlet.getValue(),
          ...storePrivateInfo,
          store_id,
          store_configs,
        });
        console.log('User', UserOutlet.getValue());
      }
    } catch (ex) {
      console.warn('onLoginResult ex', ex);
    } finally {
      setLoading(false);
    }
  }
}

async function confirmOfflineOrder(id) {
  await req(
    `${Config.apiHost}/order/offline?token=${UserOutlet.getValue().token}`,
    {
      method: 'POST',
      data: {
        id: id,
      },
    },
  );
}

async function onAdminFormSubmit({
  path,
  collection,
  instance,
  extValues,
  formData,
  primaryKey,
}) {
  if (collection === 'product' && formData.stock_type === 'period') {
    if (
      typeof formData.stock_start_date === 'string' &&
      typeof formData.stock_duration === 'number'
    ) {
      const theDate = new Date(formData.stock_start_date);
      const saleDuration = formData.stock_duration;
      theDate.setDate(theDate.getDate() + saleDuration - 1);
      formData.stock_end_date = theDate.toISOString().split('T')[0];
    }
  }

  return false;
}

async function onAfterAdminFormSubmit(
  {path, collection, instance, extValues, formData, primaryKey},
  updatedInstance,
) {
  /* wizard check */
  const WIZARD_RELATED = [
    {
      path: '/admin/config',
      key: WIZARD_STEP_KEY.INITIAL_STORE_CONFIG,
    },
    {
      path: '/admin/product_category',
      key: WIZARD_STEP_KEY.SETUP_PRODUCTS_CATEGORY,
    },
    {
      path: '/admin/products',
      key: WIZARD_STEP_KEY.CREATE_FIRST_PRODUCT,
    },
  ];
  let foundWizardRelated = WIZARD_RELATED.find(
    (s) => path.indexOf(s.path) !== -1,
  );
  if (foundWizardRelated) {
    let wizardOutlet = getOutlet('setup-wizard');
    let {steps, curStepIdx, checkAchieve} = wizardOutlet.getValue();
    let foundStep = steps.find((s) => s.key === foundWizardRelated.key);

    if (foundStep && !foundStep.achieve) {
      await checkAchieve(foundWizardRelated.key);
    }
  }

  /* cache documents */
  const storeId = getOutlet('user').getValue().store_id;

  if (!storeId) {
    return;
  }

  if (
    path.indexOf('/admin/config') > -1 ||
    path.indexOf('/admin/landing') > -1 ||
    path.indexOf('/admin/product_category') > -1
  ) {
    // await JStorage.cacheDocuments(
    //   'site',
    //   {
    //     store_id: storeId,
    //   },
    //   null,
    //   null,
    //   null,
    //   undefined,
    //   {
    //     key: `site-cache-${storeId}.json`,
    //   },
    // );

    const store_configs = await fetchStoreConfig({
      store_id: storeId,
      useCache: false,
    });

    UserOutlet.update({
      ...UserOutlet.getValue(),
      store_configs,
    });
  } else if (
    path.indexOf('/admin/products') > -1 ||
    path.indexOf('/admin/stocks') > -1
  ) {
    // await JStorage.cacheDocuments(
    //   'product',
    //   {
    //     store_id: storeId,
    //   },
    //   null,
    //   null,
    //   null,
    //   undefined,
    //   {
    //     key: `product-cache-${storeId}.json`,
    //   },
    // );
  }
}

function getReurl({title, description, image, redirectUrl}) {
  return `${Config.apiHost}/misc/reurl?title=${title}&image=${image}${
    description ? `&description=${description}` : ''
  }&redirect_url=${redirectUrl}`;
}

/**
 * **************************************************
 * custom endpoints for PopShop
 * **************************************************
 */
async function requestBillingChange(nextTier) {
  return req(
    `${Config.apiHost}/billing/request?token=${UserOutlet.getValue().token}`,
    {
      method: 'post',
      data: {
        choosen_tier: nextTier,
      },
    },
  );
}

async function requestBillingVerify({id, lastDigits, username, payDate}) {
  return req(
    `${Config.apiHost}/billing/verify?token=${UserOutlet.getValue().token}`,
    {
      method: 'post',
      data: {
        id: id,
        offline_bank_account: lastDigits,
        offline_pay_date: payDate,
        offline_username: username,
      },
    },
  );
}

async function fetchCachedSiteData(storeId) {
  // return ApiUtil.req(`${Config.rootCacheUrl}/site-cache-${storeId}.json`, {
  //   headers: {'Cache-Control': 'no-cache'},
  // });
  return JStorage.fetchAllDocuments('site', {store_id: storeId}, ['-id'], null);
}

async function fetchCachedProduct(storeId) {
  // let resp = await req(`${Config.rootCacheUrl}/product-cache-${storeId}.json`, {
  //   headers: {'Cache-Control': 'no-cache'},
  // });
  let resp = await JStorage.fetchAllDocuments(
    'product',
    {store_id: storeId},
    ['-id'],
    null,
  );

  // return resp.map(Serialize.product).filter((p) => {
  return resp.filter((p) => {
    if (p.stock_type === 'period') {
      const now = new Date();
      const [saleStartDate, saleEndState] = StockUtil.getStartEndDate(p);
      if (saleStartDate && saleEndState) {
        return (
          saleStartDate.getTime() <= now.getTime() &&
          now.getTime() < saleEndState.getTime()
        );
      }
      return true;
    } else {
      return true;
    }
  });
}

async function storeUserForgotPasswordRequest(email) {
  let resp = await req(`${Config.apiHost}/store/forgot-password/request`, {
    method: 'post',
    data: {
      username: email,
    },
  });

  return resp;
}

async function storeUserForgotPasswordConfirm(data) {
  let resp = await req(`${Config.apiHost}/store/forgot-password/confirm`, {
    method: 'post',
    data: {
      new_password: data.newPassword,
      access_token: data.accessToken,
    },
  });

  return resp;
}

async function fetchStoreConfig({store_id, useCache}) {
  const storeSettings = (useCache
    ? await fetchCachedSiteData(store_id)
    : await JStorage.fetchDocuments('site', {store_id}, undefined, null)
  ).reduce((acc, setting) => {
    acc[setting.name] = setting;
    return acc;
  }, {});

  return {
    landing: storeSettings.landing,
    product_category: storeSettings.product_category,
    config: storeSettings.config,
    store_id,
  };
}

async function fetchStorePrivateConfig(data) {
  return await req(
    `${Config.apiHost}/store/config?token=${UserOutlet.getValue().token}`,
  );
}

async function putStoreCredentials(data) {
  return await req(
    `${Config.apiHost}/store/config?token=${UserOutlet.getValue().token}`,
    {
      method: 'post',
      data,
    },
  );
}

async function bindStoreNotification(store_id) {
  const redirectUrl = `${
    Config.apiHost
  }/store/line/redirect?store_id=${store_id}&token=${
    UserOutlet.getValue().token
  }`;

  window.location.href = redirectUrl;
}

async function postContactRequest(data) {
  return await req(`${Config.apiHost}/contact/request`, {
    method: 'post',
    data,
  });
}

export {
  delay,
  setLoading,
  navigate,
  adminCanAccess,
  clientJStorageFetch,
  fetchCustomResources,
  onLoginResult,
  onAdminFormSubmit,
  onAfterAdminFormSubmit,
  onCartLoaded,
  createLogisticsOrder,
  rebuild,
  getReurl,
  confirmOfflineOrder,
  requestBillingChange,
  requestBillingVerify,
  fetchCachedProduct,
  fetchCachedSiteData,
  storeUserForgotPasswordRequest,
  storeUserForgotPasswordConfirm,
  fetchStoreConfig,
  fetchStorePrivateConfig,
  putStoreCredentials,
  bindStoreNotification,
  postContactRequest,
};
