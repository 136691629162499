import React from 'react';
import styled from 'styled-components';
import {Layout, Input, Button, message, Menu} from 'antd';
import * as AppActions from '../../AppActions';
import {useOutlet} from 'reconnect.js';
import {get as Config} from 'rev.sdk.js/Config';
import {req} from 'rev.sdk.js/Utils/ApiUtil';
import {decodeToken} from 'rev.sdk.js/Utils/jwt';

const Routes = [
  {name: '首頁', path: '/mgr-console/'},
  {name: '所有商家', path: '/mgr-console/stores/'},
  {name: '繳費記錄', path: '/mgr-console/billings/'},
  {name: '登出', path: 'logout'},
];

function getSelectedMenuKey() {
  if (typeof window === 'undefined') {
    return '';
  }

  const pathname = window.location.pathname;
  if (pathname[pathname.length - 1] !== '/') {
    return pathname + '/';
  }
  return pathname;
}

export default function MgrLayout(props) {
  const {children, location} = props;
  const [dimension] = useOutlet('dimension');
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const [initialized, setInitialized] = React.useState(false);
  const mobile = !dimension.rwd || dimension.rwd === 'mobile';
  const [user, setUser] = useOutlet('user');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const getMenuItemProps = (path) => {
    if (path === 'logout') {
      return {
        onClick: async () => {
          setUser(null);
        },
      };
    }
    return {
      selected: path === location.pathname || path === location.pathname + '/',
      onClick: () => AppActions.navigate(path),
    };
  };

  React.useEffect(() => {
    setShowMobileMenu(false);
  }, [location]);

  React.useEffect(() => {
    const _initializeSiteConfig = async () => {
      AppActions.setLoading(true);
      try {
        await AppActions.delay(500);
      } catch (ex) {
        message.warn('設定初始化失敗');
      }
      AppActions.setLoading(false);
      setInitialized(true);
    };

    if (!initialized) {
      _initializeSiteConfig();
    }
  }, [initialized]);

  const siderStyle = {
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    transition: 200,
    left: 0,
  };

  if (mobile) {
    siderStyle.left = showMobileMenu ? 0 : -200;
  }

  if (!user) {
    const mgrUserLogin = async () => {
      AppActions.setLoading(true);
      try {
        const resp = await req(`${Config().authHost}/management/sign-in`, {
          method: 'POST',
          data: {username, password},
        });

        const decoded = await decodeToken(resp.token, {verify: false});

        if (decoded.grp.indexOf('management') === -1) {
          throw new Error('not a management token');
        } else if (decoded.aud !== Config().clientId) {
          throw new Error('incorrect clientId');
        }

        Config().UserOutlet.update({
          username: decoded.sub,
          token: resp.token,
          ...decoded,
        });
      } catch (ex) {
        console.warn(ex);
        message.error('Login fail!');
      }
      setUsername('');
      setPassword('');
      AppActions.setLoading(false);
    };

    return (
      <MgrLoginWrapper>
        <form>
          <h2>Management Login</h2>
          <div className="field">
            <label>Username</label>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="field">
            <label>Password</label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Button type="primary" onClick={mgrUserLogin}>
            LOGIN
          </Button>
        </form>
      </MgrLoginWrapper>
    );
  }

  return (
    <Wrapper id="rev-mgr-layout">
      <Layout>
        <Layout
          style={{marginLeft: mobile ? 0 : 200, backgroundColor: 'white'}}>
          {initialized && children}
        </Layout>

        <Layout.Sider theme="light" style={siderStyle}>
          <AppHeader
            style={{marginBottom: 40, cursor: 'pointer'}}
            onClick={() => AppActions.navigate('/')}
          />

          <Menu mode="inline" selectedKeys={[getSelectedMenuKey()]}>
            {Routes.map(({name, path, subroutes = []}, idx) => {
              if (subroutes.length === 0) {
                return (
                  <Menu.Item key={path} {...getMenuItemProps(path)}>
                    {name}
                  </Menu.Item>
                );
              }
              return (
                <Menu.SubMenu key={path} title={name}>
                  {subroutes.map(({name, path}) => (
                    <Menu.Item key={path} {...getMenuItemProps(path)}>
                      {name}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            })}
          </Menu>
        </Layout.Sider>

        {mobile && (
          <MobileMainMenu
            onClick={() => {
              setShowMobileMenu(!showMobileMenu);
            }}>
            選單
          </MobileMainMenu>
        )}
      </Layout>
    </Wrapper>
  );
}

function AppHeader(props) {
  const {style = {}, onClick} = props;
  const [user] = useOutlet('user');

  return (
    <AppHeaderWrapper style={style} onClick={onClick}>
      <figure>
        <img src={'/images/revicon_512.png'} alt="site icon" />
      </figure>

      <div className="content">
        <h3>Bakershell</h3>
        <p>{user.sub}</p>
      </div>
    </AppHeaderWrapper>
  );
}

const Wrapper = styled.div`
  &#rev-admin-layout button.array-item-add {
    & span:first-child {
      transform: scale(1.2) translateY(1px);
    }

    & span:last-child {
      display: none;
    }
  }
`;

const AppHeaderWrapper = styled.header`
  background-color: white;
  display: flex;
  align-items: center;

  & > figure {
    padding: 10px;
    margin: 0px;

    & > img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }

  & > .content {
    padding: 8px;

    & h3 {
      color: rgba(0, 0, 0, 0.8);
      font-weight: 400;
    }

    & p {
      color: rgba(0, 0, 0, 0.8);
      padding: 0;
      margin: 0;
    }
  }
`;

const MobileMainMenu = styled.button`
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 1;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  border: none;
  outline: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MgrLoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  height: 100vh;

  & > form {
    margin: 10px;
    width: 320px;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background-color: white;
    transform: translateY(-30px);

    & > h2 {
      text-align: center;
      margin-bottom: 20px;
    }

    & > .field {
      margin-bottom: 10px;

      & > label {
        display: block;
      }
    }

    & > button {
      margin-top: 20px;
      width: 100%;
    }
  }
`;
