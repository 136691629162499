import React from 'react';
import styled from 'styled-components';
import {Modal, Button, Input, Form, message} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';

function ForgotPasswordModal(props) {
  const [info, setVisible] = useOutlet('forgot-password-modal');
  const visible = !!info;
  let admin = info && info.admin;
  const [loading, setLoading] = React.useState(false);

  const onFinish = async (values) => {
    const {email} = values;

    if (!email) {
      alert('不可為空');
      return;
    }

    await AppActions.setLoading(true);
    try {
      setLoading(true);
      await AppActions.storeUserForgotPasswordRequest(email);
      message.success('驗證信已寄出！請至信箱完成密碼重設');
    } catch (ex) {
      console.warn('EX', ex);
      message.error('發生錯誤');
    } finally {
      setLoading(false);
    }
    await AppActions.setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      destroyOnClose={true}
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={500}
      visible={visible}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}>
      <Wrapper>
        <Center>
          <h2>{admin ? '店家' : ''}重設密碼</h2>
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Form.Item
              label="註冊帳號"
              name="email"
              rules={[
                {
                  required: true,
                  message: '不可為空',
                },
                {
                  type: 'email',
                  message: '格式不正確',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item style={{textAlign: 'right'}}>
              <Button loading={loading} type="primary" htmlType="submit">
                寄送驗證信
              </Button>
            </Form.Item>
          </Form>
        </Center>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  padding: 40px 40px 8px;
`;

const Center = styled.div`
  max-width: 320px;
  width: 100%;
  margin: 0 auto;

  & h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 25px;
  }
`;

export default ForgotPasswordModal;
