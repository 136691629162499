import React from 'react';
import styled from 'styled-components';
import {getOutlet, useOutlet} from 'reconnect.js';
import qs from 'query-string';
import {withLoginRequired} from 'rev.sdk.js/Components/LoginRequired';
import SiteNavBar from '../SiteNavBar';
import {Button, Result, Space, Steps} from 'antd';

function CheckoutLayout(props) {
  const [actions] = useOutlet('actions');
  const [dimension] = useOutlet('dimension');
  const {style = {}} = props;
  const params = qs.parse(props.location.search);

  const renderCustomSection = React.useCallback(
    (sectionId) => {
      let route = props.location.pathname;

      const subRoute = route.split('/').slice().reverse()[1];
      let current = 0;

      if (subRoute === 'info') {
        current = 1;
      } else if (subRoute === 'review') {
        current = 2;
      }

      if (sectionId === 'B') {
        return (
          <Steps
            direction="horizontal"
            type="navigation"
            current={current}
            responsive={false}
            style={{marginBottom: 20}}>
            <Steps.Step title="購物車" />
            <Steps.Step title="寄送資訊" />
            <Steps.Step title="付款" />
          </Steps>
        );
      }

      return null;
    },
    [props.location.pathname],
  );

  return (
    <Wrapper id="rev-checkout-layout" style={{...style}}>
      {renderCustomSection('A')}

      <div className="content">
        {renderCustomSection('B')}

        {props.children}

        {renderCustomSection('J')}
      </div>

      {renderCustomSection('K')}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & > .content {
    max-width: var(--contentMaxWidth);
    min-height: var(--contentMinHeight);
    margin: 0 auto;
    padding: var(--basePadding);
  }

  &#rev-checkout-layout .ant-steps-navigation .ant-steps-item::after {
    display: none;
  }
`;

export default withLoginRequired(CheckoutLayout, {
  admin: false,
  SiteNavBar,
  renderLoginRequired: (options) => {
    const loginModal = getOutlet('login-modal');
    return (
      <div
        style={{
          minHeight: 'var(--contentMinHeight)',
          paddingTop: 'var(--topNavBarHeight)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Result
          icon={
            <img
              style={{width: 150}}
              src="/images/bakey.png"
              alt="permission-denied"
            />
          }
          title="尚無權限"
          subTitle="此頁面需要登入，方能瀏覽"
          extra={
            <Space direction="vertical">
              <Button
                onClick={() => {
                  loginModal.update(true);
                }}>
                登入
              </Button>
            </Space>
          }
        />
      </div>
    );
  },
});
