import React from 'react';
import {getOutlet, useOutletSetter} from 'reconnect.js';
import ActivityIndicator from '../Components/ActivityIndicator';
import LoginModal from 'rev.sdk.js/Components/LoginModal';
import ResetPasswordModal from '../Components/ResetPasswordModal';
import ForgotPasswordModal from '../Components/ForgotPasswordModal';
import useDetectDimension from 'rev.sdk.js/Hooks/useDetectDimension';

function Provider(props) {
  const setForgotPasswordModalVisible = useOutletSetter(
    'forgot-password-modal',
  );

  useDetectDimension();

  const _onBeforeLoginClick = (type, data) => {
    const StoreOutlet = getOutlet('store');
    let store = StoreOutlet.getValue();
    if (store) {
      window.localStorage.setItem('store_id', store.store_id);
    }
    return true;
  };

  return (
    <>
      {props.children}

      <LoginModal
        adminTitle={'店家登入'}
        canNormalLogin={false}
        canFacebookLogin={false}
        canGoogleLogin={false}
        canLineLogin
        onBeforeLoginClick={_onBeforeLoginClick}
        renderExtra={({admin}) =>
          admin ? (
            <p
              style={{
                fontSize: 14,
                textAlign: 'center',
                color: 'rgba(0, 0, 0, 0.45)',
              }}>
              帳號為您註冊的 email
              <br />
              無法登入？
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setForgotPasswordModalVisible({admin: true});
                }}>
                忘記密碼
              </a>
            </p>
          ) : null
        }
      />
      <ResetPasswordModal />
      <ActivityIndicator />
      <ForgotPasswordModal />
    </>
  );
}

export {Provider};
