import {getNewOutlet} from 'reconnect.js';

getNewOutlet(
  'FeatureOptions',
  {
    points: null,
    coupon: null,
  },
  {autoDelete: false},
);
