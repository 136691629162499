import React from 'react';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import {useOutlet, getOutlet} from 'reconnect.js';
import 'antd/dist/antd.less';
import qs from 'query-string';
import './Layout.css';
import AdminLayout from '../AdminLayout';
import ProfileLayout from '../ProfileLayout';
import CheckoutLayout from '../CheckoutLayout';
import MgrLayout from '../Mgrlayout';
import SiteNavBar from '../SiteNavBar';
import SiteFooter from '../SiteFooter';
import {THEME_COLOR} from '../../constants';
import useStoreDetector from '../../Hooks/useStoreDetector';
import useGoogleAnalytics from '../../Hooks/useGoogleAnalytics';
import StoreHeader from '../StoreHeader';
import Config from '../../../data.json';

function Layout(props) {
  const {children, location} = props;
  const [dimension] = useOutlet('dimension');
  const [initialized, setInitialized] = React.useState(false);

  useGoogleAnalytics({gaId: Config.gaId, gtmId: Config.gtmId});
  useStoreDetector({location});

  React.useEffect(() => {
    const isAdmin = location.pathname.indexOf('admin') !== -1;
    const isMgr = location.pathname.indexOf('mgr') !== -1;
    const params = qs.parse(location.search);

    const initialize = async (isAdmin) => {
      try {
        if (location.pathname.indexOf('/social-login') !== 0) {
          // we have both /store/socia-login and /social-login
          // only /social-login will by pass auto login logic, /social-login is for social provider redirect back path
          // by pass auto login logic in social login page
          if (params.token) {
            await getOutlet('actions')
              .getValue()
              .autoLogin({refresh: params.token, admin: isAdmin});
          } else {
            await getOutlet('actions').getValue().autoLogin({admin: isAdmin});
          }
        }
      } catch (ex) {
        console.log('autoLogin ex', ex);
        getOutlet('actions').getValue().logout();
      } finally {
        if (params.token) {
          let _params = {...params};
          delete _params.token;
          let nextRoute = qs.stringifyUrl({
            url: location.pathname,
            query: {..._params},
          });
          getOutlet('actions').getValue().navigate(nextRoute);
        }
      }

      setInitialized(true);
    };

    if (isMgr) {
      setInitialized(true);
    } else if (!initialized) {
      initialize(isAdmin);
    }
  }, [location.pathname, location.search, initialized]);

  function renderHeader() {
    if (location.pathname.indexOf('/store') === 0) {
      return <StoreHeader />;
    } else if (location.pathname === '/') {
      return null;
    } else {
      return <SiteNavBar bgColor="white" location={location} />;
    }
  }

  if (location.pathname.indexOf('promo') !== -1) {
    return <div style={{width: '100%', minHeight: '100vh'}}>{children}</div>;
  }

  const helmetElements = (
    <Helmet>
      <title>焙殼 Bakershell</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
    </Helmet>
  );

  const tryAutoLoginElements = (
    <PleaseWait>
      <img src="/images/bakey.png" alt="bakey" />
      <h2>自動登入中</h2>
      <p>請稍後...</p>
    </PleaseWait>
  );

  if (location.pathname.indexOf('mgr-console') !== -1) {
    return (
      <MgrLayout location={location}>
        {helmetElements}

        {/* overwrite site title */}
        <Helmet>
          <title>焙殼 Bakershell | 店家管理系統</title>
        </Helmet>

        {children}
      </MgrLayout>
    );
  } else if (location.pathname.indexOf('admin') !== -1) {
    if (!initialized) {
      return tryAutoLoginElements;
    }

    if (location.pathname.indexOf('/admin/forgot-password/confirm') !== -1) {
      // escap from login required
      return (
        <>
          {helmetElements}
          {children}
        </>
      );
    }

    return (
      <AdminLayout location={location}>
        {helmetElements}

        {/* overwrite site title */}
        <Helmet>
          <title>焙殼 Bakershell | 店家管理系統</title>
        </Helmet>

        {children}
      </AdminLayout>
    );
  } else if (location.pathname.indexOf('profile') !== -1) {
    if (!initialized) {
      return tryAutoLoginElements;
    }

    return (
      <Wrapper rwd={dimension.rwd}>
        {helmetElements}
        {renderHeader()}

        <ProfileLayout style={{flex: 1}} location={location}>
          {children}
        </ProfileLayout>
        <SiteFooter />
      </Wrapper>
    );
  } else if (location.pathname.indexOf('checkout') !== -1) {
    if (!initialized) {
      return tryAutoLoginElements;
    }

    return (
      <Wrapper rwd={dimension.rwd}>
        {helmetElements}
        {renderHeader()}

        <CheckoutLayout style={{flex: 1}} location={location}>
          {children}
        </CheckoutLayout>
        <SiteFooter />
      </Wrapper>
    );
  }

  let hasSiteFooter = true;
  let withBottomCart = true;

  if (location.pathname.indexOf('/store') === 0) {
    hasSiteFooter = false;
  }

  return (
    <>
      {helmetElements}
      <head>
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </head>
      <Wrapper rwd={dimension.rwd} withBottomCart={withBottomCart}>
        {renderHeader()}

        <div style={{flex: 1}}>{children}</div>
        {hasSiteFooter && <SiteFooter />}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  --contentMaxWidth: 1200px;
  --contentMinHeight: 840px;
  --topNavBarHeight: 64px;
  --basePadding: 15px 20px;
  --primaryColor: ${THEME_COLOR};
  --sectionPadding: 50px 100px;
  --sectionMobilePadding: 20px;
  --baseButtonHeight: 36px;
  --text-color: rgba(0, 0, 0, 0.8);
`;

const PleaseWait = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > img {
    width: 250px;
    height: 250px;
    object-fit: center;
    margin: 20px;
  }

  & > h2 {
    font-size: 2.5rem;
  }

  & > p {
    font-size: 2rem;
    color: #ccc;
  }
`;

export default Layout;
