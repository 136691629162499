export function getStartEndDate(product) {
  const datePattern = /\d{4}-\d{2}-\d{2}/;

  if (!datePattern.test(product.stock_start_date)) {
    return [];
  } else if (isNaN(product.stock_duration) || product.stock_duration < 1) {
    return [];
  }

  const startDate = new Date(`${product.stock_start_date}T00:00`);
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + product.stock_duration - 1);

  if (product.stock_start_time) {
    let [h, m] = product.stock_start_time.split(':');
    h = parseInt(h);
    m = parseInt(m);
    if (!isNaN(h) && !isNaN(m) && 0 <= h && h <= 23 && 0 <= m && m <= 59) {
      startDate.setHours(h);
      startDate.setMinutes(m);
    }
  }

  if (product.stock_end_time) {
    let [h, m] = product.stock_end_time.split(':');
    h = parseInt(h);
    m = parseInt(m);
    if (!isNaN(h) && !isNaN(m) && 0 <= h && h <= 23 && 0 <= m && m <= 59) {
      endDate.setHours(h);
      endDate.setMinutes(m);
    }
  }

  return [startDate, endDate];
}

export function isSoldOut(product) {
  if (!product.stock_type || product.stock_type === 'always') {
    return false;
  } else if (
    typeof product.stock_amount === 'number' &&
    typeof product.stock_sold_amount === 'number'
  ) {
    return product.stock_sold_amount >= product.stock_amount;
  }

  // don't let users to buy any products before the configure the amount properly
  return true;
}

export function getOnSaleInfo(product) {
  if (!product.stock_type || product.stock_type === 'always') {
    return {isOnSale: true, label: '加入購物車'};
  } else if (product.stock_type === 'total') {
    const soldOut = isSoldOut(product);
    return {
      isOnSale: !soldOut,
      label: !soldOut ? '加入購物車' : '已售完',
    };
  } else if (product.stock_type === 'period') {
    const [startDate, endDate] = getStartEndDate(product);

    if (!startDate || !endDate) {
      return {isOnSale: false, label: '尚未開賣'};
    }

    const now = new Date();
    const notOpen = now.getTime() < startDate.getTime();
    const closed = now.getTime() > endDate.getTime();
    const soldOut = isSoldOut(product);
    const isOnSale = !notOpen && !closed && !soldOut;
    let label = '加入購物車';

    if (notOpen) {
      label = '尚未開賣';
    } else if (closed) {
      label = '超過供應時間';
    } else if (soldOut) {
      label = '已售完';
    }

    return {isOnSale, label};
  }
}

export function getMaxQty(product) {
  if (!product.stock_type || product.stock_type === 'always') {
    return 9999;
  } else if (
    product.stock_amount === undefined ||
    product.stock_sold_amount === undefined
  ) {
    return 0;
  }

  const maxQty = product.stock_amount - (product.stock_sold_amount || 0);
  if (isNaN(maxQty)) {
    return 0;
  } else if (maxQty < 0) {
    return 0;
  }
  return maxQty;
}
