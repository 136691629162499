import React, {Fragment} from 'react';
import {Button, Input, message, Modal, Table, Form} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import styled from 'styled-components';
import {Search as SearchIcon} from '@styled-icons/boxicons-regular/Search';
import * as OrderUtil from 'rev.sdk.js/Utils/OrderUtil';

const COLUMNS = [
  {
    title: '訂單編號',
    key: 'order_id',
    dataIndex: 'order_id',
    width: 200,
    fixed: 'left',
    render: (text, record) => {
      let url = `/admin/orders/?action=detail&id=${record.__order_id}`;
      return (
        <a target={'_blank'} href={`${url}`} rel="noreferrer">
          {record.order_id}
        </a>
      );
    },
  },
  {
    title: '購買人姓名',
    key: 'buyer_name',
    dataIndex: 'buyer_name',
    width: 150,
  },
  {
    title: '購買人電話',
    key: 'buyer_phone',
    dataIndex: 'buyer_phone',
    width: 150,
  },
  {
    title: '購買人信箱',
    key: 'buyer_email',
    dataIndex: 'buyer_email',
    width: 150,
  },
];

const FIELDS = ['OrderIdOrNumber', 'buyerName', 'buyerPhone', 'buyerEmail'];

function AdminSearchOrderFixedButton(props) {
  const {offsetBottom = 32} = props;
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [records, setRecords] = React.useState([]);
  const [form] = Form.useForm();

  const _onSearchClick = async function (values) {
    try {
      let hasWord = FIELDS.some((f) => !!values[f]);
      if (!hasWord) {
        message.warn('請輸入搜尋文字');
        return;
      }

      setLoading(true);

      const searchFieldsQuery = FIELDS.reduce((acc, curr, currIdx) => {
        if (!!values[curr]) {
          let pushedValue = null;
          if (curr === 'buyerName') {
            pushedValue = {buyer_name: {$regex: `.*${values[curr]}.*`}};
          } else if (curr === 'buyerPhone') {
            pushedValue = {buyer_phone: {$regex: `.*${values[curr]}.*`}};
          } else if (curr === 'buyerEmail') {
            pushedValue = {buyer_email: {$regex: `.*${values[curr]}.*`}};
          } else if (curr === 'OrderIdOrNumber') {
            pushedValue = {
              $or: [
                {order_id: {$regex: `.*${values[curr]}.*`}},
                // {order_number: {$regex: `.*${values[curr]}.*`}},
              ],
            };
          }

          if (pushedValue) {
            acc.push(pushedValue);
          }
        }
        return acc;
      }, []);

      let respCheckout = await JStorage.fetchDocuments(
        'checkout',
        {
          $and: searchFieldsQuery,
        },
        ['-created'],
      );

      if (respCheckout.results.length === 0) {
        message.warn('無符合條件的訂單');
        setRecords([]);
        return;
      }

      let resp = await OrderUtil.mergeCheckoutJStorageRespWithOrder(
        respCheckout,
      );

      if (resp.total === 0) {
        message.warn('無符合條件的訂單');
        setRecords([]);
        return;
      }

      message.success(`符合條件訂單共${resp.total}筆`);
      setRecords(resp.results);
    } catch (err) {
      console.log('err', err);
      message.error('無符合條件的訂單');
    } finally {
      setLoading(false);
    }
  };

  const initFieldValues = React.useMemo(
    () =>
      FIELDS.reduce((acc, curr) => {
        acc[curr] = '';
        return acc;
      }, {}),
    [FIELDS],
  );

  return (
    <Fragment>
      <Button
        size="large"
        type="primary"
        shape="circle"
        icon={<SearchIcon size={26} />}
        style={{position: 'fixed', right: 32, bottom: offsetBottom}}
        onClick={() => setVisible(true)}></Button>
      <Modal
        title={'查詢訂單資料'}
        footer={null}
        bodyStyle={{padding: 0}}
        width={720}
        visible={visible}
        onOk={() => {
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}>
        <ModalContentWrapper>
          <div className="header">
            <Form
              form={form}
              layout="horizontal"
              initialValues={{
                ...initFieldValues,
              }}
              onFinish={_onSearchClick}>
              <Form.Item label="訂單編號" name="OrderIdOrNumber">
                <Input placeholder="訂單編號" />
              </Form.Item>
              <Form.Item label="購買人姓名" name="buyerName">
                <Input placeholder="購買人姓名" />
              </Form.Item>
              <Form.Item label="購買人電話" name="buyerPhone">
                <Input placeholder="購買人電話" />
              </Form.Item>
              <Form.Item label="購買人信箱" name="buyerEmail">
                <Input placeholder="購買人信箱" />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary" loading={loading}>
                  查詢
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="table">
            <Table
              rowKey={'id'}
              dataSource={records}
              columns={COLUMNS}
              scroll={{x: 'auto'}}
            />
          </div>
        </ModalContentWrapper>
      </Modal>
    </Fragment>
  );
}

const ModalContentWrapper = styled.div`
  padding: 40px 20px;
  & > .header {
    display: flex;
    margin-bottom: 20px;
  }

  & > .table {
  }
`;

export default AdminSearchOrderFixedButton;
