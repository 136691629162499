// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-generators-admin-resource-index-js": () => import("./../../../src/Generators/AdminResource/index.js" /* webpackChunkName: "component---src-generators-admin-resource-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-admin-index-js": () => import("./../../../src/Templates/Admin/index.js" /* webpackChunkName: "component---src-templates-admin-index-js" */),
  "component---src-templates-admin-preview-index-js": () => import("./../../../src/Templates/AdminPreview/index.js" /* webpackChunkName: "component---src-templates-admin-preview-index-js" */),
  "component---src-templates-admin-store-forgot-password-confirm-page-js": () => import("./../../../src/Templates/AdminStoreForgotPassword/ConfirmPage.js" /* webpackChunkName: "component---src-templates-admin-store-forgot-password-confirm-page-js" */),
  "component---src-templates-checkout-info-index-js": () => import("./../../../src/Templates/CheckoutInfo/index.js" /* webpackChunkName: "component---src-templates-checkout-info-index-js" */),
  "component---src-templates-checkout-review-index-js": () => import("./../../../src/Templates/CheckoutReview/index.js" /* webpackChunkName: "component---src-templates-checkout-review-index-js" */),
  "component---src-templates-error-index-js": () => import("./../../../src/Templates/Error/index.js" /* webpackChunkName: "component---src-templates-error-index-js" */),
  "component---src-templates-forgot-password-request-page-js": () => import("./../../../src/Templates/ForgotPassword/RequestPage.js" /* webpackChunkName: "component---src-templates-forgot-password-request-page-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/Templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-mgr-console-billing-list-js": () => import("./../../../src/Templates/MgrConsole/BillingList.js" /* webpackChunkName: "component---src-templates-mgr-console-billing-list-js" */),
  "component---src-templates-mgr-console-index-js": () => import("./../../../src/Templates/MgrConsole/index.js" /* webpackChunkName: "component---src-templates-mgr-console-index-js" */),
  "component---src-templates-mgr-console-store-list-js": () => import("./../../../src/Templates/MgrConsole/StoreList.js" /* webpackChunkName: "component---src-templates-mgr-console-store-list-js" */),
  "component---src-templates-order-list-index-js": () => import("./../../../src/Templates/OrderList/index.js" /* webpackChunkName: "component---src-templates-order-list-index-js" */),
  "component---src-templates-order-result-index-js": () => import("./../../../src/Templates/OrderResult/index.js" /* webpackChunkName: "component---src-templates-order-result-index-js" */),
  "component---src-templates-pre-social-login-index-js": () => import("./../../../src/Templates/PreSocialLogin/index.js" /* webpackChunkName: "component---src-templates-pre-social-login-index-js" */),
  "component---src-templates-privacy-index-js": () => import("./../../../src/Templates/Privacy/index.js" /* webpackChunkName: "component---src-templates-privacy-index-js" */),
  "component---src-templates-product-list-index-js": () => import("./../../../src/Templates/ProductList/index.js" /* webpackChunkName: "component---src-templates-product-list-index-js" */),
  "component---src-templates-profile-index-js": () => import("./../../../src/Templates/Profile/index.js" /* webpackChunkName: "component---src-templates-profile-index-js" */),
  "component---src-templates-register-confirm-page-js": () => import("./../../../src/Templates/Register/ConfirmPage.js" /* webpackChunkName: "component---src-templates-register-confirm-page-js" */),
  "component---src-templates-register-request-page-js": () => import("./../../../src/Templates/Register/RequestPage.js" /* webpackChunkName: "component---src-templates-register-request-page-js" */),
  "component---src-templates-social-login-index-js": () => import("./../../../src/Templates/SocialLogin/index.js" /* webpackChunkName: "component---src-templates-social-login-index-js" */),
  "component---src-templates-terms-index-js": () => import("./../../../src/Templates/Terms/index.js" /* webpackChunkName: "component---src-templates-terms-index-js" */)
}

