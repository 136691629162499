import React from 'react';
import {Modal} from 'antd';
import qs from 'query-string';
import {getOutlet} from 'reconnect.js';
import * as AppActions from '../AppActions';

function useStoreDetector({location}) {
  React.useEffect(() => {
    const params = qs.parse(location.search);

    // always update store id after url change
    if (!params.store) {
      getOutlet('store').update(null);
    } else if (params.store !== getOutlet('store').getValue()?.store_id) {
      // first update the store_id directly, without blocking UI rendering
      getOutlet('store').update({
        store_id: params.store,
      });

      // then fetch the required store information...
      async function fetchData() {
        try {
          const nextStoreOutletValue = await AppActions.fetchStoreConfig({
            store_id: params.store,
            useCache: true,
          });

          getOutlet('store').update(nextStoreOutletValue);
        } catch (ex) {
          console.warn(ex);
        }
      }

      fetchData();
    }

    if (
      location.pathname.indexOf('/store/') !== -1 &&
      location.pathname.indexOf('/store/register') === -1 &&
      location.pathname.indexOf('/store/forgot-password') === -1 &&
      location.pathname.indexOf('/store/forgot-password/confirm') === -1 &&
      location.pathname.indexOf('/store/order-result') === -1
    ) {
      if (!params.store) {
        Modal.warning({
          title: '網站偵測到您的 url 錯誤',
          content: (
            <>
              <p>請確認您的 url 正確，若此情形重複發生，請聯絡工程團隊</p>
            </>
          ),
          okText: '前往首頁',
          onOk: () => {
            console.log('press on ok! go to default page');
          },
        });
      }
    }
  }, [location]);
}

export default useStoreDetector;
